<template>
  <div>
    <VueSvgGauge :value="value" :min="min" :max="max" :start-angle="0" :end-angle="360" :inner-radius="72" :separator-step="0"
      :gauge-color="[{ offset: 0, color: color}]" :base-color="baseColor" :scale-interval="0">
      <div class="text-gray-600 flex flex-col items-center justify-center h-full">
        <p class="text-gray-500 font-thin text-3xl">{{ text }}</p>
        <div class="absolute bottom-8 bg-wilmsBlue flex-none rounded-sm items-center flex flex-col">
          <p class="text-white font-bold px-5 py-1 text-3xl">{{ valueWritten }}</p>
          <div class="absolute -bottom-3 triangle"></div>
        </div>
      </div>
    </VueSvgGauge>
  </div>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  props: ['value', 'valueWritten', 'min', 'max', 'color', 'baseColor', 'text'],
  components: {
    VueSvgGauge
  }
}
</script>

<style scoped>
  .triangle {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #0D1E61;
  }
</style>
