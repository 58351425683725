<template>
  <div>
    <VueSlider v-model="control" :marks="false" :data="marks" :max="3200" class="w-24 mt-3" @drag-end="$emit('control', control)" />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import '@/assets/styles/iot/slider.css'

export default {
  props: ['value'],
  components: {
    VueSlider
  },
  data () {
    return {
      marks: [0, 500, 1000, 1500, 2000, 2500, 3000, 3200],
      control: 0
    }
  },
  mounted () {
    this.control = this.value
  }
}
</script>
