<script>
import { Bar } from 'vue-chartjs'
import moment from 'moment'

export default {
  extends: Bar,
  props: ['chartData', 'time', 'width'],
  data () {
    return {
      options: {
        tooltips: {
          mode: 'index',
          displayColors: false,
          callbacks: {
            title: (tooltipItems) => {
              if (this.time === 'hour') return moment(tooltipItems[0].label).format('HH:mm, DD MMMM YYYY')
              return moment(tooltipItems[0].label).format('dddd, DD MMMM YYYY')
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            },
            gridLines: {
              borderDash: [8, 4],
              display: true,
              drawBorder: false
            }
          }],
          xAxes:
          [{
            type: 'time',
            time: {
              unit: this.time,
              displayFormats: {
                hour: 'HH:mm',
                day: 'DD MMM',
                week: 'DD MMMM YYYY',
                month: 'MMM YYYY'
              }
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartObject, this.options)
  },
  watch: {
    chartData () {
      this.renderChart(this.chartObject, this.options)
    },
    time () {
      this.options.scales.xAxes[0].time.unit = this.time
      this.renderChart(this.chartObject, this.options)
    }
  },
  computed: {
    dataPoints () {
      const grouped = {}

      if (this.time === 'hour') {
        for (const point of this.chartData) {
          const d = new Date(point.createdAt)

          const hour = d.getHours().toString() + d.getTime().toString()

          if (!(hour in grouped)) {
            grouped[hour] = []
          }

          grouped[hour].push(point)
        }
      } else if (this.time === 'day') {
        for (const point of this.chartData) {
          const d = new Date(point.createdAt)
          d.setHours(0, 0, 0, 0)

          const day = d.getTime()

          if (!(day in grouped)) {
            grouped[day] = []
          }

          grouped[day].push(point)
        }
      } else if (this.time === 'week') {
        for (const point of this.chartData) {
          const d = new Date(point.createdAt)
          d.setHours(0, 0, 0, 0)
          const week = moment(d).week().toString() + moment(d).year().toString()

          if (!(week in grouped)) {
            grouped[week] = []
          }

          grouped[week].push(point)
        }
      } else {
        for (const point of this.chartData) {
          const d = new Date(point.createdAt)
          d.setHours(0, 0, 0, 0)
          const month = moment(d).month().toString() + moment(d).year().toString()

          if (!(month in grouped)) {
            grouped[month] = []
          }

          grouped[month].push(point)
        }
      }

      const labels = []
      const averages = []

      for (const date in grouped) {
        const points = grouped[date]

        const values = points.map(point => point.values).flat()

        const sum = values.reduce((a, b) => a + b, 0)
        const average = sum / values.length
        const chartXvalue = new Date(grouped[date][0].createdAt).toISOString()
        labels.push(chartXvalue)
        averages.push(average.toFixed(1))
      }

      return [labels, averages]
    },
    chartObject () {
      const [labels, average] = this.dataPoints

      var gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, this.width, 0)

      gradient.addColorStop(0, 'rgba(106, 126, 153, 0.5)')
      gradient.addColorStop(0.5, 'rgba(106, 126, 153, 0.25)')
      gradient.addColorStop(1, 'rgba(106, 126, 153, 0)')
      
      return {
        labels,
        datasets: [
          {
            label: 'Average',
            data: average,
            pointBackgroundColor: '#0D1E61',
            pointBorderColor: '#0D1E61',
            type: 'line',
            fill: true,
            backgroundColor: gradient,
            borderColor: '#0D1E61'
          }
        ]
      }
    }
  }
}
</script>
