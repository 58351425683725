<template>
  <div class="container">
    <p class="text-wilmsBlue text-2xl font-bold mt-12">{{ $t('Graphs') }} <span v-if="location">{{ location.home.name }}</span></p>
    <p class="text-gray-500 font-medium -mt-1 mb-6">{{ $t('Discover everything about this location') }}</p>
    <div class="flex justify-end mb-5">
      <div class="flex cursor-pointer py-2 px-5 rounded-sm border-2 border-wilmsBlue mr-3">
        <p class="text-wilmsBlue font-semibold mr-3">{{ $t('Export graphs') }}</p>
        <div class="w-6 h-6 bg-wilmsBlueBackground rounded-full flex flex-wrap content-center justify-center cursor-pointer">
          <Icon class="text-wilmsBlue text-xs" icon="fa:arrow-down"/>
        </div>
      </div>
      <div @click="reboot" class="cursor-pointer bg-wilmsBlue py-2 px-5 text-white font-semibold rounded-sm">
        {{ $t('Reboot all') }}
      </div>
    </div>
    <div class="grid grid-cols-3 gap-5 mb-5 lg:grid-cols-2 md:grid-cols-1">
      <div class="w-full shadow-md flex px-3 py-6 items-center" v-if="stats">
        <div class="flex-none">
          <div class="w-12 h-12 bg-green-100 rounded-full flex flex-wrap content-center justify-center ">
            <img class="mx-auto h-8" alt="" src="@/assets/img/iot/np_fan_green.svg"/>
          </div>
        </div>
        <div class="flex flex-col ml-3">
          <div class="flex flex-row gap-3 items-center">
            <p class="text-green-500 font-bold text-lg">{{ $t('Out') }}</p>
            <div class="border-r-default border-gray-300 h-5"></div>
            <p class="text-wilmsBlue font-bold text-lg">{{ $t('Operation time') }} (QA)</p>
          </div>
          <div class="flex flex-row gap-2 items-center">
            <p class="text-gray-400">{{ sumStats.FaTime | readableSeconds('days') }}</p>
            <div class="border-r-default border-gray-300 h-4"></div>
            <p class="text-gray-400">{{ sumStats.FaTime | readableSeconds('hours') }}</p>
          </div>
        </div>
      </div>
      <div class="w-full shadow-md flex px-3 py-6 items-center" v-if="stats">
        <div class="flex-none">
          <div class="w-12 h-12 bg-green-100 rounded-full flex flex-wrap content-center justify-center ">
            <img class="mx-auto h-8" alt="" src="@/assets/img/iot/np_fan_green.svg"/>
          </div>
        </div>
        <div class="flex flex-col ml-3">
          <div class="flex flex-row gap-3 items-center">
            <p class="text-green-500 font-bold text-lg">{{ $t('In') }}</p>
            <div class="border-r-default border-gray-300 h-5"></div>
            <p class="text-wilmsBlue font-bold text-lg">{{ $t('Operation time') }} (QD)</p>
          </div>
          <div class="flex flex-row gap-2 items-center">
            <p class="text-gray-400">{{ sumStats.FdTime | readableSeconds('days') }}</p>
            <div class="border-r-default border-gray-300 h-4"></div>
            <p class="text-gray-400">{{ sumStats.FdTime | readableSeconds('hours') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-5 mb-10 lg:grid-cols-2 sm:grid-cols-1">
      <div class="col-start-1 w-full h-full shadow-md lg:col-span-2">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Averages') }}</p>
          </div>
          <div class="bg-white rounded-sm flex-none flex m-1">
            <div class="cursor-pointer m-1 py-1 px-3 text-wilmsBlue font-bold rounded-sm text-xs" @click="averageTime = 'day'" :class="{ 'bg-wilmsBlue text-white': 'day' === averageTime }">
              {{ $t('Day') }}
            </div>
            <div class="cursor-pointer m-1 py-1 px-3 text-wilmsBlue font-bold rounded-sm text-xs" @click="averageTime = 'week'" :class="{ 'bg-wilmsBlue text-white': 'week' === averageTime }">
              {{ $t('Week') }}
            </div>
            <div class="cursor-pointer m-1 py-1 px-3 text-wilmsBlue font-bold rounded-sm text-xs" @click="averageTime = 'month'" :class="{ 'bg-wilmsBlue text-white': 'month' === averageTime }">
              {{ $t('Month') }}
            </div>
          </div>
        </div>
        <div ref="small" class="flex flex-row mx-2 gap-2">
          <div class="mb-2 w-12 h-12 rounded-sm shadow border flex flex-col items-center justify-center cursor-pointer" v-for="type in ['leaf',  'tint', 'thermometer-full']" :key="type" @click="averageChart = type" :class="{ 'bg-wilmsBlue': type === averageChart }">
            <Icon class="cursor-pointer text-wilmsBlue text-xl" :icon="'fa:' + type" :class="{ 'text-white': type === averageChart }"/>
            <span class="text-white text-xs font-semibold" v-if="type === averageChart"><span v-if="type === 'leaf'">CO2</span><span v-if="type === 'tint'">%</span><span v-if="type === 'thermometer-full'">°C</span></span>
          </div>
        </div>
        <MinMaxAverageChart :height="200" :chartData="averageChartData" :time="averageTime" :width="this.$refs.small.clientWidth" v-if="averagesDay"/>
      </div>
      <div class="col-span-2 w-full h-full shadow-md">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Recent measurements') }}</p>
          </div>
        </div>
        <div ref="big" class="flex flex-row mx-2 gap-2">
          <div class="mb-2 w-12 h-12 rounded-sm shadow border flex flex-col items-center justify-center cursor-pointer" v-for="type in ['leaf',  'tint', 'thermometer-full']" :key="type" @click="recentChart = type" :class="{ 'bg-wilmsBlue': type === recentChart }">
            <Icon class="cursor-pointer text-wilmsBlue text-xl" :icon="'fa:' + type" :class="{ 'text-white': type === recentChart }"/>
            <span class="text-white text-xs font-semibold" v-if="type === recentChart"><span v-if="type === 'leaf'">CO2</span><span v-if="type === 'tint'">%</span><span v-if="type === 'thermometer-full'">°C</span></span>
          </div>
        </div>
        <MinMaxAverageChart :height="200" :chartData="recentChartData" :time="'hour'" :width="this.$refs.big.clientWidth" v-if="averagesDay"/>
      </div>
      <div class="col-start-1 w-full h-full shadow-md lg:order-1 sm:order-none sm:col-span-2">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Quadrant') }} A</p>
          </div>
          <div class="flex-none mr-3 my-auto">
            <p class="font-bold text-wilmsBlue text-xs">{{ $t('Waste air') }} <span class="text-gray-500 font-medium">{{ $t('Inside') }} > {{ $t('Outside') }}</span></p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-10 px-8 my-4" v-if="last">
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.Ta)" :valueWritten="intFixed(last.Ta, 1)" :min="-20" :max="40" :color="'#ebb434'" :base-color="'#f7edd7'" :text="'°C'"/>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="rounded(last.Fa)" :valueWritten="speed(last.Fa)" :min="0" :max="3200" :color="'#ebb434'" :base-color="'#f7edd7'" :text="'RPM'"/>
            <Slider :value="rounded(last.Fa)" class="w-full" v-on:control="v => changeRpm(v, 0)"/>
          </div>
        </div>
      </div>
      <div class="col-span-2 w-full h-full shadow-md lg:order-3">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Quadrant') }} C</p>
          </div>
          <div class="flex-none mr-3 my-auto">
            <!--<p class="font-bold text-red-700 text-xs">{{ $t('Check sensor') }} 4 !</p>-->
          </div>
        </div>
        <div class="grid grid-cols-4 gap-10 px-24 my-4 sm:grid-cols-2 lg:px-10" v-if="last">
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.Tc)" :valueWritten="intFixed(last.Tc, 1)" :min="-20" :max="40" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'°C'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('Temperature') }}</p>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.RHc)" :valueWritten="intFixed(last.RHc, 0)" :min="0" :max="100" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'%'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('Humidity') }}</p>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="last.CO2c" :valueWritten="last.CO2c" :min="0" :max="5000" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'PPM'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('CO2') }}</p>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="last.VOCc" :valueWritten="last.VOCc" :min="0" :max="1000" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'PPM'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('VOC') }}</p>
          </div>
        </div>
      </div>
      <div class="col-start-1 w-full h-full shadow-md lg:order-2 sm:order-none lg:col-start-auto sm:col-span-2">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Quadrant') }} B</p>
          </div>
          <div class="flex-none mr-3 my-auto">
            <p class="font-bold text-wilmsBlue text-xs">{{ $t('Fresh air') }} <span class="text-gray-500 font-medium">{{ $t('Outside') }} > {{ $t('Inside') }}</span></p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-10 px-8 my-4" v-if="last">
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.Tb)" :valueWritten="intFixed(last.Tb, 1)" :min="-20" :max="40" :color="'#ebb434'" :base-color="'#f7edd7'" :text="'°C'"/>
          </div>
        </div>
      </div>
      <div class="col-span-2 w-full h-full shadow-md lg:order-4">
        <div class="m-2 bg-wilmsBlueBackground rounded-sm flex h-10">
          <div class="flex-auto">
            <p class="text-wilmsBlue font-bold text-lg ml-3 mt-1 whitespace-nowrap">{{ $t('Quadrant') }} D</p>
          </div>
          <div class="flex-none mr-3 my-auto">
            <!--<p class="font-bold text-red-700 text-xs">{{ $t('Check sensor') }} 3 !</p>-->
          </div>
        </div>
        <div class="grid grid-cols-4 gap-10 px-24 my-4 sm:grid-cols-2 lg:px-10" v-if="last">
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.Td)" :valueWritten="intFixed(last.Td, 1)" :min="-20" :max="40" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'°C'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('Temperature') }}</p>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="rounded(last.Fd)" :valueWritten="speed(last.Fd)" :min="0" :max="3200" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'RPM'"/>
            <Slider :value="rounded(last.Fa)" class="w-full" v-on:control="v => changeRpm(v, 9)"/>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="short(last.RHd)" :valueWritten="intFixed(last.RHd, 0)" :min="0" :max="100" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'%'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('Humidity') }}</p>
          </div>
          <div class="flex flex-col items-center">
            <GaugeChart :value="last.VOCd" :valueWritten="last.VOCd" :min="0" :max="1000" :color="'#4f9c22'" :base-color="'#eef5eb'" :text="'PPM'"/>
            <p class="w-max text-wilmsBlue font-semibold mt-2">{{ $t('VOC') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GaugeChart from '@/components/iot/charts/GaugeChart'
import Slider from '@/components/iot/charts/Slider'
import MinMaxAverageChart from '@/components/iot/charts/MinMaxAverageChart'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    GaugeChart,
    Slider,
    MinMaxAverageChart,
    Icon
  },
  data () {
    return {
      location: null,
      reconnect: true,
      connection: null,
      averagesDay: null,
      averagesWeek: null,
      averagesMonth: null,
      last: null,
      stats: null,
      averageChart: 'leaf',
      averageTime: 'day',
      recentChart: 'leaf'
    }
  },
  async mounted () {
    this.$emit('changeHeader', [[this.$t('IOT'), { name: 'iot.installer' }], [this.$t('Locations'), { name: 'iot.installer.homes' }],
      [this.$t('Details location'), { name: 'iot.installer.details', params: { home: this.$route.params.home } }],
      [this.$t('Graphs'), { name: 'iot.installer.graphs', params: { home: this.$route.params.home, device: this.$route.params.device } }]])
    await this.fetchData()
    this.$store.getters['HomesModule/getHomes'].forEach(location => {
      if (location.home.id === parseInt(this.$route.params.home)) this.location = location
    })
    moment.locale(this.$i18n.locale)
    // this.createWS()
  },
  methods: {
    async fetchData () {
      if (this.$store.getters['HomesModule/getHomes'].length === 0) await this.$store.dispatch('HomesModule/fetchHomes')
      await this.$store.dispatch('DevicesModule/fetchAveragesDay', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device, time: 'day' })
      await this.$store.dispatch('DevicesModule/fetchAveragesWeek', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device, time: 'week' })
      await this.$store.dispatch('DevicesModule/fetchAveragesMonth', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device, time: 'month' })
      await this.$store.dispatch('DevicesModule/fetchLast', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device })
      await this.$store.dispatch('DevicesModule/fetchStats', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device })
      this.averagesDay = this.$store.getters['DevicesModule/getAveragesDay']
      this.averagesWeek = this.$store.getters['DevicesModule/getAveragesWeek']
      this.averagesMonth = this.$store.getters['DevicesModule/getAveragesMonth']
      this.last = this.$store.getters['DevicesModule/getLast']
      this.stats = this.$store.getters['DevicesModule/getStats']
    },
    short (value) {
      return value / 100
    },
    intFixed (value, fixed) {
      return this.short(value).toFixed(fixed)
    },
    rounded (value) {
      return Math.round(value / 500) * 500
    },
    speed (value) {
      return value > 3200 ? 0 : value
    },
    averagesSorted (data) {
      return data?.slice().sort((a, b) => {
        const ta = new Date(a.createdAt).getTime()
        const tb = new Date(b.createdAt).getTime()

        return ta - tb
      })
    },
    async changeRpm (value, index) {
      await this.$store.dispatch('DevicesModule/changeRPM', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device, index: index, value: value })
    },
    async reboot () {
      await this.$store.dispatch('DevicesModule/rebootDevice', { homeId: this.$route.params.home, roomId: this.$route.params.room, deviceId: this.$route.params.device })
    },
    createWS () {
      const deviceId = this.$route.params.device
      const channel = `device@${deviceId}`
      this.connection = new WebSocket(`${process.env.VUE_APP_WS_URL}?channel=${channel}`)

      this.connection.onmessage = (event) => {
        const message = JSON.parse(event.data)
        this.last = message.data
      }

      this.connection.onopen = () => {
        console.log('opened websocket')
      }

      this.connection.onclose = () => {
        if (!this.reconnect) return
        setTimeout(this.createWS, 2000)
      }
    }
  },
  filters: {
    readableSeconds (seconds, format) {
      seconds /= 1000
      var d = Math.floor(seconds / (3600 * 24))
      var h = Math.floor(seconds % (3600 * 24) / 3600)
      var dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '0 days'
      var hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '0 hours'

      if (format === 'days') return dDisplay
      return hDisplay
    }
  },
  computed: {
    sumStats () {
      return this.stats?.reduce((carry, stat) => {
        carry.FaTime += stat.FaTime
        carry.FdTime += stat.FdTime

        return carry
      }, { FaTime: 0, FdTime: 0 })
    },
    averageChartData () {
      var data = null
      if (this.averageTime === 'day') data = this.averagesDay
      else if (this.averageTime === 'week') data = this.averagesWeek
      else data = this.averagesMonth
      return this.averagesSorted(data).map(point => {
        let values = []

        if (this.averageChart === 'leaf') values = [point.CO2c]
        if (this.averageChart === 'tint') values = [point.RHc / 100, point.RHd / 100]
        if (this.averageChart === 'thermometer-full') values = [point.Ta / 100, point.Tb / 100, point.Tc / 100, point.Td / 100]

        return {
          createdAt: point.createdAt,
          values
        }
      })
    },
    recentChartData () {
      const length = this.averagesSorted(this.averagesDay).length
      return this.averagesSorted(this.averagesDay).filter((point, i) => (length - 24 < i + 1)).map((point, i) => {
        let values = []

        if (this.recentChart === 'leaf') values = [point.CO2c]
        if (this.recentChart === 'tint') values = [point.RHc / 100, point.RHd / 100]
        if (this.recentChart === 'thermometer-full') values = [point.Ta / 100, point.Tb / 100, point.Tc / 100, point.Td / 100]

        return {
          createdAt: point.createdAt,
          values
        }
      })
    }
  }
}
</script>
